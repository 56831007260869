<template>
  <div class="container-fluid text-light">
    <div class="row">
      <div class="col-sm">
        <select class="form-select" aria-label="Language" v-model="language">
          <option value="de-DE">Alemán</option>
          <option value="ar-MA">Árabe</option>
          <option value="ca-ES">Catalán</option>
          <option value="zh-CN">Chino</option>
          <option value="es-ES" selected>Español</option>
          <option value="fr-FR">Francés</option>
          <option value="hi-IN">Hindi</option>
          <option value="en-US">Inglés (Estados Unidos)</option>
          <option value="en-GB">Inglés (Reino Unido)</option>
          <option value="it-IT">Italiano</option>
          <option value="pt-PT">Portugués</option>
          <option value="ro-RO">Rumano</option>
          <option value="ru-RU">Ruso</option>
        </select>
      </div>
      <div class="col-sm mt-2 mt-sm-0">
        <div class="d-grid gap-2">
          <button
            class="btn mb-2"
            type="button"
            @click="buttonClick"
            :class="styles[recording].style"
          >
            {{ styles[recording].message }}
          </button>
        </div>
      </div>
    </div>
    <div>
      <textarea
        class="form-control textarea"
        placeholder="Transcripción original"
        id="floatingTextarea"
        v-model="wssListen.message"
        disabled
      >
      </textarea>
      <div
        class="col-sm mt-2 mt-sm-0"
        v-if="wssListen.message != '' && recording == 'stop'"
      >
        <div class="d-grid gap-2">
          <button class="btn mt-2 btn-primary" type="button" @click="download">
            Descargar transcripción
          </button>
        </div>
      </div>
      <textarea
        class="form-control textarea2 mt-3"
        placeholder="Vocabulario de entrenamiento separadas por (;) -> palabra1;palabra2;palabra3;"
        id="floatingTextareaVocabulary"
        v-model="vocabulary"
      >
      </textarea>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { ref, watch, reactive } from "vue";
import useMic from "../hooks/useMic";
import useWebsocket from "../hooks/useWebsocket";
export default {
  name: "HomeView",
};
</script>

<script setup>
let recording = ref("stop");
const styles = reactive({
  stop: { style: "btn-success", message: "Comenzar transcripción" },
  connecting: { style: "btn-warning", message: "Conectando..." },
  connected: { style: "btn-danger", message: "Detener transcripción" },
});
let language = ref("es-ES");
const code = "leSwtuH";
const user = "e8d899cfcab5d9e44fbc42e094a66733";
const vocabulary = ref("");
const microphone = reactive(useMic());
const trans = [
  "de",
  "es",
  "fr",
  "en",
  "it",
  "ja",
  "pt",
  "ro",
  "ar",
  "zh-Hans",
  "hi",
  "ru",
  "ca",
];
let wssTranscription = reactive(
  useWebsocket("translation", code, user, language.value, vocabulary.value, trans)
);
let wssListen = reactive(useWebsocket("listen", code, user));

const buttonClick = () => {
  if (recording.value == "stop") {
    recording.value = "connecting";
    startMic();
  } else {
    recording.value = "stop";
    stopMic();
  }
};

const startMic = () => {
  try {
    wssTranscription.setLanguage(language.value);
    wssTranscription.setVocabulary(vocabulary.value);
    microphone.start();
    wssListen.connect();
    wssTranscription.connect();
  } catch (e) {
    console.log(e);
  }
};

const stopMic = () => {
  microphone.stop();
  wssListen.disconnect();
  wssTranscription.disconnect();
  recording.value = "stop";
};

const download = () => {
  let element = document.createElement("a");
  let fileDownload = "transcription.txt";
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(wssListen.message)
  );
  element.setAttribute("download", fileDownload);
  element.style.display = "none";
  element.click();
};

watch(
  () => microphone.content,
  (n, o) => {
    if (n != null && n != "" && wssTranscription.isConnected()) {
      wssTranscription.send(n);
    }
  }
);

watch(
  () => wssListen.message,
  (n, o) => {
    if (n != 0 && n != "") {
      let textArea = document.getElementById("floatingTextarea");
      textArea.scrollTop = textArea.scrollHeight;
    }
  }
);

watch(
  () => wssTranscription.connected,
  (n, o) => {
    if (n) {
      recording.value = "connected";
    } else {
      stopMic();
    }
  }
);
</script>

<style scoped>
.textarea {
  font-size: 1.4em;
  height: 50vh;
}
.textarea2 {
  font-size: 1.4em;
  height: 20vh;
}
</style>
