import RecordRTC from 'recordrtc'
import { ref } from 'vue'

function useMic () {
  let recorder = null
  let isEdge =
    navigator.userAgent.indexOf('Edge') !== -1 &&
    (!!navigator.msSaveOrOpenBlob || !!navigator.msSaveBlob)
  let content = ref(null)
  let connected = ref(false)

  function start () {
    navigator.mediaDevices
      .getUserMedia({
        audio: isEdge
          ? true
          : {
              echoCancellation: false
            }
      })
      .then(async stream => {
        let options = {
          type: 'audio',
          numberOfAudioChannels: 1,
          checkForInactiveTracks: true,
          bufferSize: 16384,
          timeSlice: 250,
          desiredSampRate: 16000,
          recorderType: RecordRTC.StereoAudioRecorder,
          ondataavailable: blob => {
            content.value = blob
          }
        }
        recorder = RecordRTC(stream, options)
        recorder.onStateChanged = state => {
          connected.value = state != 'recording' ? false : true
        }
        recorder.startRecording()
      })
  }

  function stop () {
    recorder.stopRecording()
    recorder.reset()
  }

  return {
    content,
    connected,
    start,
    stop,
  }
}

export default useMic
