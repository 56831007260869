<template>
  <div class="container-fluid">
    <header
      class="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-1 mb-1 border-bottom"
    >
      <div class="col-12 col-md-3 mb-1 mb-md-0 text-md-start" v-if="path == '/viewer'">
        <a href="/" class="d-inline-flex link-body-emphasis text-decoration-none">
          <img src="./assets/copla.png" alt="Copla's Logo" class="logo" />
        </a>
      </div>
      <div class="col-12 mb-1 mb-md-0 text-center" v-if="path != '/viewer'">
        <a href="/" class="d-inline-flex link-body-emphasis text-decoration-none">
          <img src="./assets/copla.png" alt="Copla's Logo" class="logo" />
        </a>
      </div>
      <div class="col-md-auto text-center" v-if="path == '/viewer'">
        <div class="row">
          <div class="col-2">
            <label
              for="backgroundColor"
              class="col-form-label col-form-label-sm me-2 me-sm-1"
              >Fondo</label
            >
            <input
              type="color"
              class="form-control-sm me-sm-2 color-picker"
              id="backgroundColor"
              title="Fondo"
              :value="backgroundColor"
              v-on:change="changeBackground"
            />
          </div>
          <div class="col-2">
            <label for="text" class="col-form-label col-form-label-sm me-2 me-sm-1"
              >Texto</label
            >
            <input
              type="color"
              class="form-control-sm me-sm-2 color-picker"
              id="textColor"
              title="Texto"
              :value="color"
              v-on:change="changeColor"
            />
          </div>
          <div class="col-4 text-center">
            <label
              for="backgroundColor"
              class="col-form-label col-form-label-sm me-2 me-sm-1"
              >Fuente</label
            >
            <select
              class="form-select form-select-sm"
              aria-label="Default select example"
              style="width: 100%"
              v-on:change="changeFontFamily"
            >
              <option value="Arial, sans-serif">Arial</option>
              <option value="'Brush Script MT', cursive">Brush Script MT</option>
              <option value="'Courier New',monospace">Courier New</option>
              <option value="Garamond, serif">Garamond</option>
              <option value="Georgia, serif">Georgia</option>
              <option value="Helvetica, sans-serif">Helvetica</option>
              <option value="Roboto, sans-serif">Roboto</option>
              <option value="Tahoma, sans-serif">Tahoma</option>
              <option value="'Times New Roman', serif">Times New Roman</option>
              <option value="'Trebuchet MS', sans-serif">Trebuchet</option>
              <option value="Verdana, sans-serif" selected>Verdana</option>
            </select>
          </div>
          <div class="col-4">
            <label
              for="backgroundColor"
              class="col-form-label col-form-label-sm me-2 me-sm-1"
              >Tamaño</label
            >
            <select
              class="form-select form-select-sm"
              aria-label="Default select example"
              style="width: 100%"
              v-on:change="changeFontSize"
            >
              <option value="14">14</option>
              <option value="18">18</option>
              <option value="24">24</option>
              <option value="30">30</option>
              <option value="36">36</option>
              <option value="42">42</option>
              <option value="48" selected>48</option>
              <option value="54">54</option>
              <option value="60">60</option>
              <option value="72">72</option>
              <option value="84">84</option>
              <option value="96">96</option>
              <option value="120">120</option>
              <option value="150">150</option>
              <option value="180">180</option>
              <option value="200">200</option>
              <option value="250">250</option>
            </select>
          </div>
        </div>
      </div>
    </header>
  </div>
  <router-view
    :color="color"
    :backgroundColor="backgroundColor"
    :fontSize="fontSize"
    :fontFamily="fontFamily"
  />
</template>

<script>
// @ is an alias to /src
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
</script>

<script setup>
const route = useRoute();
let color = ref("#FFFFFF");
let backgroundColor = ref("#000000");
let fontFamily = ref("Verdana, sans-serif");
let fontSize = ref("48");
let path = ref("/");
path.value = route.path;

watch(
  () => route.path,
  (n, o) => {
    path.value = n;
  }
);

const changeColor = (val) => {
  color.value = val.srcElement.value;
};

const changeBackground = (val) => {
  backgroundColor.value = val.srcElement.value;
};

const changeFontSize = (val) => {
  fontSize.value = val.srcElement.value;
};

const changeFontFamily = (val) => {
  fontFamily.value = val.srcElement.value;
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: white;
}

.logo {
  height: 50px;
}
</style>
